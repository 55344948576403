<template>
  <div class="borrowdetail">
    <van-nav-bar
      :title="$t('BORRORWDETAIL')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <div class="borrorwlong">
        <van-cell>
          <div slot="title">{{$t('BORRORWPLAN')}}</div>
        </van-cell>
      </div>
      <div class="progressbar">
        <van-steps :active="active" active-color="#f60">
          <van-step>{{$t('SUBMITSUCCEED')}}</van-step>
          <van-step v-if="changeA">{{$t('ARRIVEMONEYSUCCEED')}}</van-step>
          <van-step v-else>{{$t('TOFAILURE')}}</van-step>
        </van-steps>
      </div>
    </div>
    <div class="Warmprompt">
      <div class="top">
        <span class="colorred">{{$t('WARMPROMPT')}}：</span><span class="van-multi-ellipsis--l2 pading20">{{strAS}}</span>
      </div>
      <!-- <div class="bm">
        <span>line：</span> line
      </div> -->
      <div class="bm">
        <span>{{$t('CONTACTME')}}：</span>
        <van-image v-if="stutaimg" @click="toUrl" class="liimg" width="100" height="30" :src="imgurl" />
        <!-- <van-button @click="toVip" v-else size="small" type="primary">{{$t('OPENVVIP')}}</van-button> -->
      </div>
    </div>
    <div class="container m10">
      <div class="borrorwlong">
        <van-cell>
          <div slot="title">{{$t('BORRORWDETAIL')}}</div>
        </van-cell>
      </div>
      <div class="detailList">
        <div class="ordernumber p10">{{$t('ORDERFORMNUM')}}<span class="num">{{id}}</span></div>
        <div class="ordernumber p10">{{$t('BORRORWMONEYTIME')}}<span class="num">{{months}}{{$t('MONTHS')}}</span></div>
        <div class="ordernumber p10">{{$t('EVERYISSUE')}}<span class="num">Rp{{monthly}}</span></div>
        <div v-if="existStuta" class="ordernumber p10">
          <!-- <van-button @click="Dialoghetong" type="default" block>{{$t('Lookatthecontract')}}</van-button> -->
        <!-- <van-button @click="Dialoghetong" type="default" block>นายโชติวิศว์ อุดมประเสริฐกุล</van-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { details } from '@/api/my'
// import { Dialog } from 'vant'
import './css/my.css'
export default {
  name: 'borrowdetail',
  data () {
    return {
      active: 0,
      // 说明
      explain: '',
      // 订单编号
      id: '',
      // 借款期限
      months: '',
      // 借款金额
      money: '',
      // 每月还款金额
      monthly: '',
      // line
      link: '',
      //
      imgurl: require('./img/line.png'),
      stutaimg: false,
      changeA: true,
      strAS: '',
      existStuta: false,
      qianming: require('./img/qianming.jpg'),
      hetongcontent: ''
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async loaddetails () {
      const { data } = await details({
        id: this.$route.params.id,
        url: document.domain
      })
      // this.explain = data.data.explain
      this.id = data.data.id
      this.months = data.data.months
      this.monthly = data.data.monthly
      this.link = data.data.link
      this.hetongcontent = data.data.agreement
      if (data.data.status === '1') {
        this.strAS = this.$t('WENXINGTISHI')
        this.active = 0
      } else if (data.data.status === '2') {
        this.existStuta = true
        this.active = 1
      } else if (data.data.status === '3') {
        this.changeA = false
        this.active = 1
      }
      if (this.link) {
        this.stutaimg = true
      }
      console.log(data)
    },
    toUrl () {
      // location.href = this.link
    },
    Dialoghetong () {
      // Dialog.alert({
      //   title: 'ขออนุมัติสินเชื่อเนื้อหาสัญญา',
      //   message: this.hetongcontent + '<br/><img width="100%" height="100" src="' + this.qianming + '" alt="">',
      //   messageAlign: 'left',
      //   confirmButtonText: this.$t('CONFIRM')
      // }).then(() => {
      //   // on close
      // })
      this.$router.push({
        name: 'ht',
        params: {
          content: this.hetongcontent + '<br/><img width="100%" height="100" src="' + this.qianming + '" alt="">'
        }
      })
    }
  },
  created () {
    this.loaddetails()
  }
}
</script>

<style scoped>
.liimg {
  /* margin-top: 10px; */
  vertical-align: middle;
}
.colorred {
  color: red;
}
.pading20 {
  padding: 0 20px;
}
.Warmprompt {
  margin: 10px 0;
  /* height: 40px; */
  background: #FFF1D8;
  font-size: 15px;
  line-height: 40px;
  text-indent: 1em;
}
.container {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.borrorwlong {
  border-bottom: 1px solid #e4e4e4;
}
.progressbar {
  box-sizing: border-box;
  padding: 30px;
}
.m10 {
  margin-top: 10px;
}
.detailList {
  box-sizing: border-box;
  padding-top: 30px;
  padding-left: 10px;
}
.ordernumber {
  font-size: 16px;
  color: #666;
}
.p10 {
  padding: 10px 0;
}
.num {
  margin-left: 40px;
}
.block {
  font-size: 13px;
  padding-top: 5px;
  padding-left: 25px;
  display: block;
}
</style>
